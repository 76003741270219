import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserService } from 'src/app/services/user/user.service';
import { MyConfig } from 'src/assets/config/app-config';
import { Location } from '@angular/common';
import { StorageService } from 'src/app/services/storage/storage.service';
import { CommonFunctionsService } from 'src/app/services/common/common-functions.service';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.page.html',
    styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit {

    myconfig: any = MyConfig;
    resetPassForm: any;
    isPasswordValid: boolean;
    isPasswordEmpty: boolean = true;
    isConfirmPasswordValid: boolean;
    isConfirmPasswordEmpty: boolean = true;
    showPassword: boolean = false;
    showConfirmPassword: boolean = false;
    submitAttempt: boolean = false;
    isPasswordMinLengthValid: boolean;
    isPasswordUpperCaseValid: boolean;
    isPasswordNumericValid: boolean;
    isPasswordSpecialCharValid: boolean;

    @Input() uid;
    @Input() actionfrom;

    constructor(
        public formBuilder: FormBuilder,
        public modalCtrl: ModalController,
        public toastController: ToastController,
        public alertController: AlertController,
        public userService: UserService,
        public navigationService: NavigationService,
        public location: Location,
        public storageService: StorageService,
        public commonFunctions: CommonFunctionsService,
    ) { }

    ngOnInit() {
        try {
            this.initForm();
        }
        catch (error) { }
    }

    initForm() {
        try {
            this.resetPassForm = this.formBuilder.group({
                password: ['', Validators.compose([Validators.pattern(MyConfig.regex.passwordRegex), Validators.required])],
                confirmPassword: ['', Validators.compose([Validators.pattern(MyConfig.regex.passwordRegex), Validators.required])],
            })
            if (this.actionfrom != "fromlogin") {
                this.uid = JSON.parse(this.storageService.getStorage('currentUser'))._id;
            }
        }
        catch (error) { }
    }

    checkValidPassword(e) {
        try {
            let passwordRegex = new RegExp(MyConfig.regex.passwordRegex);
            let passwordRegex_Upper_case = new RegExp(MyConfig.regex.passwordRegex_Upper_case);
            let passwordRegex_Numeric = new RegExp(MyConfig.regex.passwordRegex_Numeric);
            let passwordRegex_Special_char = new RegExp(MyConfig.regex.passwordRegex_Special_char);
            (e.target.value == '') ? this.isPasswordEmpty = true : this.isPasswordEmpty = false;
            passwordRegex.test(e.target.value) == true ? this.isPasswordValid = true : this.isPasswordValid = false;
            e.target.value.length > 7 ? this.isPasswordMinLengthValid = true : this.isPasswordMinLengthValid = false;
            passwordRegex_Upper_case.test(e.target.value) == true ? this.isPasswordUpperCaseValid = true : this.isPasswordUpperCaseValid = false;
            passwordRegex_Numeric.test(e.target.value) == true ? this.isPasswordNumericValid = true : this.isPasswordNumericValid = false;
            passwordRegex_Special_char.test(e.target.value) == true ? this.isPasswordSpecialCharValid = true : this.isPasswordSpecialCharValid = false;
            console.log(
                "this.isPasswordEmpty ->", this.isPasswordEmpty,
                "this.isPasswordValid ->", this.isPasswordValid,
                "this.isPasswordMinLengthValid ->", this.isPasswordMinLengthValid,
                "this.isPasswordUpperCaseValid ->", this.isPasswordUpperCaseValid,
                "this.isPasswordNumericValid ->", this.isPasswordNumericValid,
                "this.isPasswordSpecialCharValid ->", this.isPasswordSpecialCharValid);
        }
        catch (error) { }
    }

    checkValidConfirmPassword(e) {
        console.log("🚀 ~ file: reset-password.page.ts:81 ~ ResetPasswordPage ~ checkValidConfirmPassword ~ e:", e)
        try {
            (e.target.value == '') ? this.isConfirmPasswordEmpty = true : this.isConfirmPasswordEmpty = false;
            e.target.value == this.resetPassForm.value.password ? this.isConfirmPasswordValid = true : this.isConfirmPasswordValid = false;
            console.log("this.isConfirmPasswordEmpty ->", this.isConfirmPasswordEmpty, "this.isConfirmPasswordValid ->", this.isConfirmPasswordValid);
        }
        catch (error) { }
    }

    toggleViewPassword() {
        try {
            this.showPassword = !this.showPassword;
            console.log("🚀 ~ file: reset-password.page.ts ~ line 83 ~ ResetPasswordPage ~ toggleViewPassword ~ this.showPassword", this.showPassword)
        } catch (error) { }
    }

    toggleViewConfirmPassword() {
        try {
            this.showConfirmPassword = !this.showConfirmPassword;
            console.log("🚀 ~ file: reset-password.page.ts ~ line 91 ~ ResetPasswordPage ~ toggleViewConfirmPassword ~ this.showConfirmPassword", this.showConfirmPassword)
        } catch (error) { }
    }

    async resetPass() {
        this.submitAttempt = true;

        console.log("🚀 ~ file: reset-password.page.ts:103 ~ ResetPasswordPage ~ resetPass ~ this.isPasswordValid:", this.isPasswordValid, "this.isConfirmPasswordValid:", this.isConfirmPasswordValid)
        if (this.isPasswordValid && this.isConfirmPasswordValid) {
            await this.commonFunctions.showLoading("Please wait...");
            let resetPasswordObject = {
                id: this.uid,
                password: this.resetPassForm.value.password
            }
            this.userService.resetPassword(resetPasswordObject).subscribe(async (res: any) => {
                await this.commonFunctions.hideLoading();
                if (res.success == true) this.presentPasswordChangedSuccessfullAlert();
                else this.showTryAgainToast();
            }, async error => {
                await this.commonFunctions.hideLoading();
                console.log(error)
            })
        }
    }

    async showTryAgainToast() {
        const TryAgainToast = await this.toastController.create({
            message: 'Something went wrong, Please try again.',
            duration: 5000,
            color: 'secondary',
        });
        TryAgainToast.present();

        await TryAgainToast.onDidDismiss().then(() => {
            // this.resend = true;
        });
    }

    async presentPasswordChangedSuccessfullAlert() {
        const passwordChangedSuccessfullAlert = await this.alertController.create({
            cssClass: 'present-confirm-go-back-alert',
            message: 'Password changed successfully. Login using new password',
            buttons: [
                {
                    text: 'Okay',
                    handler: () => {
                        this.closeModal();
                    },
                },
            ],
        });

        await passwordChangedSuccessfullAlert.present();

    }

    closeModal() {
        if (this.actionfrom == "fromlogin") this.modalCtrl.dismiss();
        else this.location.back();
    }

    test(e) {
        console.log("🚀 ~ file: reset-password.page.ts:156 ~ ResetPasswordPage ~ test ~ e:", e)
    }

}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/join-service-alert/join-service-alert.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,WAAA;AACJ","sourcesContent":[".outer-wrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 90%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

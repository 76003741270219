import { ProgramService } from 'src/app/services/program/program.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-join-service-alert',
    templateUrl: './join-service-alert.component.html',
    styleUrls: ['./join-service-alert.component.scss'],
})
export class JoinServiceAlertComponent implements OnInit {
    msg: string = "";

    constructor(
        public modalCtrl: ModalController,
        public programService: ProgramService
    ) {
        // this.getServiceAlertMessage();
    }

    ngOnInit() { }

    // getServiceAlertMessage() {
    //     this.programService.getJoinServiceAlertMsg().subscribe((res: any) => {
    //         console.log("🚀 ~ file: join-service-alert.component.ts:24 ~ JoinServiceAlertComponent ~ this.programService.getJoinServiceAlertMsg ~ res:", res.data.msg);
    //         this.msg = res.data.msg;
    //     })

    //     this.msg = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    // }

    close() {
        this.modalCtrl.dismiss();
    }

}

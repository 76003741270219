import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserService } from 'src/app/services/user/user.service';
import { MyConfig } from 'src/assets/config/app-config';
import { UserInterface } from 'src/app/interfaces/user-interface';
import { CategoriesPage } from '../categories/categories.page';
import { ResetPasswordPage } from '../reset-password/reset-password.page';
import { CommonFunctionsService } from 'src/app/services/common/common-functions.service';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.page.html',
    styleUrls: ['./registration.page.scss'],
    providers: []
})
export class RegistrationPage implements OnInit {

    myconfig: any = MyConfig;
    userType: any = "serviceSeeker";
    registerForm: FormGroup;
    verifyForm: FormGroup;
    isFirstNameValid: boolean;
    isFirstNameEmpty: boolean = true;
    isLastNameValid: boolean;
    isLastNameEmpty: boolean = true;
    isEmailValid: boolean;
    isEmailEmpty: boolean = true;
    isPhoneValid: boolean;
    isPhoneEmpty: boolean = true;
    isPasswordValid: boolean;
    isPasswordEmpty: boolean = true;
    isConfirmPasswordValid: boolean;
    isConfirmPasswordEmpty: boolean = true;
    isAgeBoxChecked: boolean;
    showPassword: boolean = false;
    showConfirmPassword: boolean = false;
    submitAttempt: boolean = false;
    isPasswordMinLengthValid: boolean;
    isPasswordUpperCaseValid: boolean;
    isPasswordNumericValid: boolean;
    isPasswordSpecialCharValid: boolean;
    isRegistered: boolean = false;
    isVerified: boolean = false;
    countryCode: string = '+1';
    registeredUserId: string = ""
    registeredUserPhone: string = "";
    registeredUserEmail : string = "";
    signuperror: any = "";
    emptyOTP: boolean = false;
    otpservererro: any = "";
    @Input() action;
    @Input() phone;
    @Input() email;
    @Input() uid;
    @ViewChild('register', { static: false }) registrationElem: ElementRef;
    @ViewChild('verify', { static: false }) verifyCodeElem: ElementRef;

    constructor(
        public commonFunctions: CommonFunctionsService,
        public formBuilder: FormBuilder,
        public navigationService: NavigationService,
        public userService: UserService,
        public modalCtrl: ModalController
    ) { }

    ngOnInit() {
        try {
            this.initForm();
            console.log("🚀 ~ file: registration.page.ts:63 ~ RegistrationPage ~ ngOnInit ~ this.action:", this.action)
        }
        catch (error) { }
    }

    ngAfterViewInit() {
        this.selectFormToDisplay(this.action);
        console.log("🚀 ~ file: registration.page.ts:52 ~ RegistrationPage ~ uid:", this.uid)
    }

    initForm() {
        try {
            this.registerForm = this.formBuilder.group({
                profileType: ['serviceSeeker', Validators.required],
                first: ['', Validators.compose([Validators.pattern(MyConfig.regex.nameRegex), Validators.maxLength(30), Validators.required])],
                last: ['', Validators.compose([Validators.pattern(MyConfig.regex.nameRegex), Validators.maxLength(30), Validators.required])],
                email: ['', Validators.compose([Validators.pattern(MyConfig.regex.emailRegex), Validators.required])],
                phone: ['', Validators.compose([Validators.pattern(MyConfig.regex.phoneRegex), Validators.required])],
                password: ['', Validators.compose([Validators.pattern(MyConfig.regex.passwordRegex), Validators.required])],
                confirmPassword: ['', Validators.compose([Validators.pattern(MyConfig.regex.passwordRegex), Validators.required])],
                ageCheck: [false, Validators.compose([Validators.requiredTrue])]
            })
            this.verifyForm = this.formBuilder.group({
                code1: ['', Validators.required],
                code2: ['', Validators.required],
                code3: ['', Validators.required],
                code4: ['', Validators.required],
                code5: ['', Validators.required],
                code6: ['', Validators.required],
            })
            this.signuperror = "";
            this.otpservererro = "";
        }
        catch (error) { }
    }

    selectFormToDisplay(action) {
        switch (action) {
            case 'verify':
                this.hideRegistrationForm();
                this.showVerifyForm();
                this.resendOtp();
                break;
            case 'forgot':
                this.hideRegistrationForm();
                this.showVerifyForm();
                break;

            default:
                this.showRegistrationForm();
                this.hideVerifyForm();
                break;
        }
    }

    selectedUserType(userType) {
        try {
            this.userType = userType;
            this.registerForm.patchValue({ profileType: userType })
        }
        catch (error) { }
    }

    checkValidFirstName(e) {
        try {
            let firstRegex = new RegExp(MyConfig.regex.nameRegex);
            (e.target.value == '') ? this.isFirstNameEmpty = true : this.isFirstNameEmpty = false;
            (firstRegex.test(e.target.value) == true && e.target.value != '' && this.registerForm.controls.first.status == 'VALID') ? this.isFirstNameValid = true : this.isFirstNameValid = false;
            console.log("this.isFirstNameEmpty ->", this.isFirstNameEmpty, "this.isFirstNameValid ->", this.isFirstNameValid);

        }
        catch (error) { }
    }

    checkValidLastName(e) {
        try {
            let LastRegex = new RegExp(MyConfig.regex.nameRegex);
            (e.target.value == '') ? this.isLastNameEmpty = true : this.isLastNameEmpty = false;
            (LastRegex.test(e.target.value) == true && e.target.value != '' && this.registerForm.controls.last.status == 'VALID') ? this.isLastNameValid = true : this.isLastNameValid = false;
            console.log("this.isLastNameEmpty ->", this.isLastNameEmpty, "this.isLastNameValid ->", this.isLastNameValid);
        }
        catch (error) { }
    }

    checkValidEmail(e) {
        try {
            let emailRegex = new RegExp(MyConfig.regex.emailRegex);
            (e.target.value == '') ? this.isEmailEmpty = true : this.isEmailEmpty = false;
            emailRegex.test(e.target.value) == true ? this.isEmailValid = true : this.isEmailValid = false;
            console.log("this.isEmailEmpty ->", this.isEmailEmpty, "this.isEmailValid ->", this.isEmailValid);
        }
        catch (error) { }
    }

    checkValidPhone(e) {
        try {
            let phoneRegex = new RegExp(MyConfig.regex.phoneRegex);
            (e.target.value == '') ? this.isPhoneEmpty = true : this.isPhoneEmpty = false;
            phoneRegex.test(e.target.value) == true ? this.isPhoneValid = true : this.isPhoneValid = false;
            console.log("this.isPhoneEmpty ->", this.isPhoneEmpty, "this.isPhoneValid ->", this.isPhoneValid);
        }
        catch (error) { }
    }

    checkValidPassword(e) {
        try {
            let passwordRegex = new RegExp(MyConfig.regex.passwordRegex);
            let passwordRegex_Upper_case = new RegExp(MyConfig.regex.passwordRegex_Upper_case);
            let passwordRegex_Numeric = new RegExp(MyConfig.regex.passwordRegex_Numeric);
            let passwordRegex_Special_char = new RegExp(MyConfig.regex.passwordRegex_Special_char);
            (e.target.value == '') ? this.isPasswordEmpty = true : this.isPasswordEmpty = false;
            passwordRegex.test(e.target.value) == true ? this.isPasswordValid = true : this.isPasswordValid = false;
            e.target.value.length > 7 ? this.isPasswordMinLengthValid = true : this.isPasswordMinLengthValid = false;
            passwordRegex_Upper_case.test(e.target.value) == true ? this.isPasswordUpperCaseValid = true : this.isPasswordUpperCaseValid = false;
            passwordRegex_Numeric.test(e.target.value) == true ? this.isPasswordNumericValid = true : this.isPasswordNumericValid = false;
            passwordRegex_Special_char.test(e.target.value) == true ? this.isPasswordSpecialCharValid = true : this.isPasswordSpecialCharValid = false;
            console.log(
                "this.isPasswordEmpty ->", this.isPasswordEmpty,
                "this.isPasswordValid ->", this.isPasswordValid,
                "this.isPasswordMinLengthValid ->", this.isPasswordMinLengthValid,
                "this.isPasswordUpperCaseValid ->", this.isPasswordUpperCaseValid,
                "this.isPasswordNumericValid ->", this.isPasswordNumericValid,
                "this.isPasswordSpecialCharValid ->", this.isPasswordSpecialCharValid);
        }
        catch (error) { }
    }

    checkValidConfirmPassword(e) {
        try {
            (e.target.value == '') ? this.isConfirmPasswordEmpty = true : this.isConfirmPasswordEmpty = false;
            e.target.value == this.registerForm.value.password ? this.isConfirmPasswordValid = true : this.isConfirmPasswordValid = false;
            console.log("this.isConfirmPasswordEmpty ->", this.isConfirmPasswordEmpty, "this.isConfirmPasswordValid ->", this.isConfirmPasswordValid);
        }
        catch (error) { }
    }

    checkAgeBoxChecked(e) {
        try {
            if (e.target.checked) {
                this.registerForm.patchValue({ ageCheck: true })
                this.isAgeBoxChecked = true;
            }
            else {
                this.isAgeBoxChecked = false;
            }
            console.log("this.isAgeBoxChecked ->", this.isAgeBoxChecked);
        }
        catch (error) { }
    }

    toggleViewPassword() {
        try {
            this.showPassword = !this.showPassword;
            console.log("🚀 ~ file: registration.page.ts ~ line 112 ~ RegistrationPage ~ toggleViewPassword ~ this.showPassword", this.showPassword)
        } catch (error) { }
    }

    toggleViewConfirmPassword() {
        try {
            this.showConfirmPassword = !this.showConfirmPassword;
            console.log("🚀 ~ file: registration.page.ts ~ line 157 ~ RegistrationPage ~ toggleViewConfirmPassword ~ this.showConfirmPassword", this.showConfirmPassword)
        } catch (error) { }
    }

    async registerUser() {
        try {
            this.submitAttempt = true;
            console.log("this.isPasswordEmpty ->", this.isPasswordEmpty, "this.isPasswordValid ->", this.isPasswordValid, "this.submitAttempt ->", this.submitAttempt);
            this.signuperror = "";

            if (
                this.isFirstNameValid == true &&
                this.isLastNameValid == true &&
                this.isEmailValid == true &&
                // this.isPhoneValid == true &&
                this.isPasswordValid == true &&
                this.isConfirmPasswordValid == true &&
                this.isAgeBoxChecked == true
            ) {
                let signupUserObj = {
                    first_name: this.registerForm.value.first,
                    last_name: this.registerForm.value.last,
                    full_name: this.registerForm.value.first + " " + this.registerForm.value.last,
                    email: this.registerForm.value.email.toLowerCase(),
                    password: this.registerForm.value.password,
                    // phone: `${this.countryCode}${this.registerForm.value.phone}`,

                }
                await this.commonFunctions.showLoading("Processing...")
                this.userService.userSignUp(signupUserObj).subscribe(async (res: any) => {
                    console.log("registered response", res);
                    await this.commonFunctions.hideLoading();
                    this.signuperror = "";
                    if (res) {
                        this.isRegistered = true;
                        this.registeredUserId = res.data.uid
                        // this.registeredUserPhone = signupUserObj.phone
                        this.registeredUserEmail = signupUserObj.email
                        this.hideRegistrationForm();
                        this.showVerifyForm();
                    }
                }, async error => {
                    await this.commonFunctions.hideLoading();
                    this.signuperror = error.error.message;
                })

            }
            else console.log("complete form with valid data");
        }
        catch (error) {
            console.log("signup phone error");

        }
    }

    showRegistrationForm() {
        try {
            this.registrationElem;
        } catch (error) {

        }
    }

    hideRegistrationForm() {
        try {
            this.registrationElem.nativeElement.className = 'registerStepBody-away';
            // setTimeout(() => {
            this.registrationElem.nativeElement.className = 'registerStepBody-away d-none';
            // }, 500);
        } catch (error) {

        }
    }

    showVerifyForm() {
        try {
            this.emptyOTP = false;
            this.otpservererro = "";
            this.verifyCodeElem.nativeElement.className = 'verifyStepBody'
        } catch (error) {

        }
    }

    hideVerifyForm() {
        try {
            this.verifyCodeElem.nativeElement.className = 'verifyStepBody-away'
        } catch (error) {

        }
    }

    async verifyOtp(a, b, c, d, e, f) {
        let otpCode = a.value + b.value + c.value + d.value + e.value + f.value;
        console.log("🚀 ~ file: registration.page.ts:354 ~ RegistrationPage ~ verifyOtp ~ otpCode:", otpCode)
        this.otpservererro = "";
        if (otpCode == "") {
            this.emptyOTP = true;
        } else {
            this.emptyOTP = false;
            let otpObject = {
                otpCode: otpCode,
                email: this.registeredUserEmail ?this.registeredUserEmail : this.email,
                // phone: this.registeredUserPhone ? this.registeredUserPhone : this.phone,
                user_id: this.registeredUserId != "" ? this.registeredUserId : this.uid,
                reason: "verify-signup"
            }
            console.log("otp object", otpObject);
            await this.commonFunctions.showLoading("Verifying OTP...")
            this.userService.verifyOTP(otpObject).subscribe(async (res: any) => {
                console.log("verify otp response", res);
                await this.commonFunctions.hideLoading();
                this.otpservererro = "";
                if (res.success == true && (this.action == 'register' || this.action == 'verify')) this.closeModal()
                else if (res.success == true && this.action == 'forgot') this.closeModal(), this.openResetPasswordModal();

            }, async error => {
                await this.commonFunctions.hideLoading();
                this.otpservererro = error.error.message;
            })
        }
    }

    resendOtp() {
        let requestObject = {
            email: this.registeredUserEmail ? this.registeredUserEmail : this.email,
            // user_id: this.registerdUserId
        }

        this.userService.resendOTP(requestObject).subscribe(async (res: any) => {
            console.log("here is the new otp response", res);
            if (res.success) {
                await this.commonFunctions.presentToast(res.message)
            }
            this.registeredUserId = res.data.uid
        })
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    verify() {
        this.closeModal();
    }

    move(e, p, c, n) {
        this.emptyOTP = false;
        try {
            if (c.value.length == 1 && n != "") n.focus();
            if (e.key === 'Backspace' && p != "") p.focus();
        } catch (error) {

        }
    }

    paste(e, d1, d2, d3, d4, d5, d6) {
        this.emptyOTP = false;
        try {
            let txt = (e.clipboardData || window['clipboardData']).getData('text');
            if (txt.length == 6) {
                setTimeout(() => {
                    d1.value = txt[0];
                }, 100);
                setTimeout(() => {
                    d2.value = txt[1];
                    d2.focus();
                }, 200);
                setTimeout(() => {
                    d3.value = txt[2];
                    d3.focus();
                }, 300);
                setTimeout(() => {
                    d4.value = txt[3];
                    d4.focus();
                }, 400);
                setTimeout(() => {
                    d5.value = txt[4];
                    d5.focus();
                }, 500);
                setTimeout(() => {
                    d6.value = txt[5];
                    d6.focus();
                }, 600);
            }
        }
        catch (error) {

        }
    }

    async openResetPasswordModal() {
        try {
            const resetPasswordModal = await this.modalCtrl.create({
                component: ResetPasswordPage,
                backdropDismiss: false,
                componentProps: {
                    uid: this.uid,
                    actionfrom: "fromlogin"
                }
            });
            resetPasswordModal.present();
        } catch (error) {

        }
    }


}

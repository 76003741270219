// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m-t-2 {
  margin-top: 2rem;
}

.m-t-3 {
  margin-top: 3rem;
}

.p-146-hr {
  padding: 0 146px;
}

.p-top {
  padding-top: 4rem;
}

.code {
  width: 20%;
  float: left;
}

.code-input-height {
  align-self: flex-end;
  width: 55px;
  margin-right: 10px;
  text-align: center;
}

.phone-input {
  width: 80%;
}

.web-font {
  font-size: 20px;
}

.header-alignment-center {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

ion-icon.close.ios.ion-color.ion-color-primary.hydrated {
  font-size: 30px;
  transform: rotate(45deg);
  position: absolute;
  top: 4rem;
  right: 2rem;
  cursor: pointer;
  /* border: 1px solid; */
  z-index: 100;
  border-radius: 50%;
  padding: 0.5rem;
}

@media screen and (max-width: 970px) {
  .web-font {
    font-size: 16px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/forgot-password/forgot-password.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,UAAA;EACA,WAAA;AACJ;;AAEA;EAII,oBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;AAFJ;;AAKA;EACI,UAAA;AAFJ;;AAKA;EACI,eAAA;AAFJ;;AAKA;EACI,aAAA;EACA,mBAAA;EACA,kCAAA;AAFJ;;AAKA;EACI,eAAA;EACA,wBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAFJ;;AAKA;EACI;IACI,0BAAA;EAFN;AACF","sourcesContent":[".m-t-2 {\n    margin-top: 2rem;\n}\n\n.m-t-3 {\n    margin-top: 3rem;\n}\n\n.p-146-hr {\n    padding: 0 146px;\n}\n\n.p-top {\n    padding-top: 4rem\n}\n\n.code {\n    width: 20%;\n    float: left;\n}\n\n.code-input-height {\n    // height: 58px;\n    // display: grid;\n    // place-content: end;\n    align-self: flex-end;\n    width: 55px;\n    margin-right: 10px;\n    text-align: center;\n}\n\n.phone-input {\n    width: 80%;\n}\n\n.web-font {\n    font-size: 20px;\n}\n\n.header-alignment-center {\n    display: flex;\n    align-items: center;\n    justify-content: center !important;\n}\n\nion-icon.close.ios.ion-color.ion-color-primary.hydrated {\n    font-size: 30px;\n    transform: rotate(45deg);\n    position: absolute;\n    top: 4rem;\n    right: 2rem;\n    cursor: pointer;\n    /* border: 1px solid; */\n    z-index: 100;\n    border-radius: 50%;\n    padding: 0.5rem;\n}\n\n@media screen and (max-width: 970px) {\n    .web-font {\n        font-size: 16px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

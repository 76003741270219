import { StorageService } from './../storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReviewsService {
    baseServerUrl: any = environment.API_URL
    starRating: any = ['star-outline', 'star-outline', 'star-outline', 'star-outline', 'star-outline']
    ratingCount: number
    constructor(
        private http: HttpClient,
        public storageService: StorageService
    ) { }

    getReviews(programId) {
        if(!this.storageService.getStorage('currentUserId')){
            return this.http.get(this.baseServerUrl + `/reviews/getReviews/public/${programId}`).pipe(
                tap((data) => { }),
                catchError((err) => { throw (err) }))
        }
        else{
            return this.http.get(this.baseServerUrl + `/reviews/getReviews/${programId}`).pipe(
                tap((data) => { }),
                catchError((err) => { throw (err) }))
        }
      
    }

    getMyAllReviews() {
        return this.http.get(this.baseServerUrl + `/reviews/get-individual-user-review/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    getAllReviewsForMyProgram() {
        return this.http.get(this.baseServerUrl + `/reviews/get-instructor-reviews/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    PostReview(reviewsObj) {
        return this.http.post(this.baseServerUrl + `/reviews/postReview`, reviewsObj).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    UpdateReview(reviewId, reviewsObj) {
        return this.http.patch(this.baseServerUrl + `/reviews/updateReview/${reviewId}`, reviewsObj).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    getAvgStarRatingFunction(programId) {
        this.getAvgStarRating(programId).subscribe(
            (response: any) => { 
                this.starRating = response.data.starRating as string[];
                this.ratingCount = response.data.count
                console.log("Star Ratings:", this.starRating, "Count:", this.ratingCount);

            },
            (error) => {
                console.error("Error fetching star ratings:", error);
            }
        );
    }
    getAvgStarRating(programId) {
        return this.http.get(this.baseServerUrl + `/reviews/average-star-rating/${programId}`)
    }

}

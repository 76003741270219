import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { MyConfig } from 'src/assets/config/app-config';
import { RegistrationPage } from '../registration/registration.page';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.page.html',
    styleUrls: ['./forgot-password.page.scss'],
    providers: []
})
export class ForgotPasswordPage implements OnInit {

    myconfig: any = MyConfig;
    forgotPassForm: any;
    isPhoneValid: boolean;
    isPhoneEmpty: boolean = true;
    submitAttempt: boolean = false;
    resend: boolean = false;
    countryCode: any = '+1'
    requestedCodeUserId: any;
    isEmailValid : boolean;
    isEmailEmpty: boolean = true;

    constructor(
        public formBuilder: FormBuilder,
        public modalCtrl: ModalController,
        public userService: UserService,
        public navigationService: NavigationService,
        public toastController: ToastController
    ) { }

    ngOnInit() {
        try {
            this.initForm();
        }
        catch (error) { }
    }

    initForm() {
        try {
            this.forgotPassForm = this.formBuilder.group({
                email: ['', Validators.compose([Validators.pattern(MyConfig.regex.emailRegex), Validators.required])]
            })
        }
        catch (error) { }
    }

    checkValidPhone(e) {
        try {
            let phoneRegex = new RegExp(MyConfig.regex.phoneRegex);
            (e.target.value == '') ? this.isPhoneEmpty = true : this.isPhoneEmpty = false;
            phoneRegex.test(e.target.value) == true ? this.isPhoneValid = true : this.isPhoneValid = false;
            console.log("this.isPhoneEmpty ->", this.isPhoneEmpty, "this.isPhoneValid ->", this.isPhoneValid);
        }
        catch (error) { }
    }

    checkValidEmail(e) {
        try {
            let emailRegex = new RegExp(MyConfig.regex.emailRegex);
            (e.target.value == '') ? this.isEmailEmpty = true : this.isEmailEmpty = false;
            emailRegex.test(e.target.value) == true ? this.isEmailValid = true : this.isEmailValid = false;
            console.log("this.isEmailEmpty ->", this.isEmailEmpty, "this.isEmailValid ->", this.isEmailValid);
        }
        catch (error) { }
    }

    send() {
        this.submitAttempt = true;

        this.resendOtp().then(res => {
            if (res) this.isEmailValid ? (this.showPasswordResetLinkToast(), this.openRegistrationFormModal('forgot'), this.closeModal()) : "";
        })
        // this.navigationService.toResetPassword();

    }

    async showPasswordResetLinkToast() {
        const passwordResetLinkToast = await this.toastController.create({
            message: 'Please check your phone and click on the provided link reset your password.',
            duration: 5000,
            color: 'secondary',
        });
        passwordResetLinkToast.present();

        await passwordResetLinkToast.onDidDismiss().then(() => {
            this.resend = true;
        });
    }

    async openRegistrationFormModal(action) {
        try {

            const registrationFormModal = await this.modalCtrl.create({
                component: RegistrationPage,
                backdropDismiss: false,
                componentProps: {
                    action: action,
                    email: this.forgotPassForm.value.email,
                    phone: "",
                    uid: this.requestedCodeUserId
                }
            });
            registrationFormModal.present();

            registrationFormModal.onDidDismiss().then((res) => {
                console.log("🚀 ~ file: forgot-password.page.ts:90 ~ ForgotPasswordPage ~ registrationFormModal.onDidDismiss ~ res:", res)
            })
        } catch (error) {

        }
    }

    resendOtp() {
        return new Promise((resolve, reject) => {

            let requestObject = {
                email: this.forgotPassForm.value.email,
                // user_id: this.registerdUserId
            }

            this.userService.resendOTP(requestObject).subscribe((res: any) => {
                console.log("here is the new otp response", res);
                this.requestedCodeUserId = res.data.uid
                resolve(res);
            }, (err) => {
                reject(err);
            })
        })
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

}

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { catchError } from "rxjs/operators";
import { StorageService } from "./services/storage/storage.service";


@Injectable()
export default class ApiInterceptor implements HttpInterceptor {
    constructor(public http :HttpClient, public localStorage : StorageService ){
        
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       // throw new Error("Method not implemented.");
       let headers
       if (req.url.includes("s3.amazonaws.com")){
        if (req.url.includes("video")) {
            headers = new HttpHeaders({
                'Content-Type': 'application/octet-stream',
            });
        }
        else if (req.url.includes("audio")) {
            headers = new HttpHeaders({
                'Content-Type': 'audio/mpeg',
            });
        }
        else {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });
        }
        let url = `${req.url}`
        let newReq = req.clone({headers, url})
        return next.handle(newReq)
       }
       else if (req.headers.has('skipInterceptor')) {
        const headers = req.headers.delete('skipInterceptor');
        const clonedRequest = req.clone({ headers });
        return next.handle(clonedRequest);
       }
       else{
        const  token =  this.localStorage.getStorage('token')
        const authToken = token ? token : '' 
        let headers = new HttpHeaders({
            'Authorization' : authToken,
            'Content-Type' : 'application/json'
        })
        let url = `${req.url}`
        let newReq = req.clone({headers, url})
        return next.handle(newReq)
       }
  
    }
    
    
} 
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p.h7 {
  margin: 0 1rem;
}

.align-sub-header {
  display: flex;
  align-items: center;
}

.back-icon {
  font-size: 24px;
  font-weight: 700;
  padding: 1rem;
}

ion-icon.ios.hydrated {
  font-size: 30px;
  color: var(--ion-color-custom-accent);
}

.page-sub-header {
  font-size: 24px;
  font-weight: 700;
}

.bold-medium {
  font-weight: 700;
  color: var(--ion-color-medium);
}

ion-item {
  --inner-padding-end: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/reset-password/reset-password.page.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,aAAA;AACJ;;AAEA;EACI,eAAA;EACA,qCAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,8BAAA;AACJ;;AAEA;EACI,wBAAA;AACJ","sourcesContent":["p.h7 {\n    margin: 0 1rem;\n}\n\n.align-sub-header {\n    display: flex;\n    align-items: center;\n}\n\n.back-icon {\n    font-size: 24px;\n    font-weight: 700;\n    padding: 1rem;\n}\n\nion-icon.ios.hydrated {\n    font-size: 30px;\n    color: var(--ion-color-custom-accent);\n}\n\n.page-sub-header {\n    font-size: 24px;\n    font-weight: 700;\n}\n\n.bold-medium {\n    font-weight: 700;\n    color: var(--ion-color-medium);\n}\n\nion-item{\n    --inner-padding-end: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

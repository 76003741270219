import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class AnnouncementsService {
    baseServerUrl: any = environment.API_URL

    constructor(
        private http: HttpClient,
        public storageService: StorageService
    ) { }

    getAnnouncements(programId) {

        if(!this.storageService.getStorage('currentUserId')){
            return this.http.get(this.baseServerUrl + `/announcements/getAnnouncements/public/${programId}`).pipe(
                tap((data) => { }),
                catchError((err) => { throw (err) }))
        }else{
            return this.http.get(this.baseServerUrl + `/announcements/getAnnouncements/${programId}`).pipe(
                tap((data) => { }),
                catchError((err) => { throw (err) }))
        }
    
    }

    PostAnnouncement(announcementsObj) {
        return this.http.post(this.baseServerUrl + `/announcements/postAnnouncements`, announcementsObj).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    UpdateAnnouncement(announcementId, announcementsObj) {
        return this.http.patch(this.baseServerUrl + `/announcements/updateAnnouncements/${announcementId}`, announcementsObj).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

}
